import { useEffect } from 'react'

declare global {
	interface Window {
		dataLayer: any[]
		[key: string]: any // For dynamic property access like w[l]
	}
}

const useGoogleTagManagerScript = () => {
	useEffect(() => {
		const gtmId = ENV.GTM_ID

		if (!gtmId || process.env.NODE_ENV !== 'production') {
			return
		}

		;(function (
			w: Window & { [key: string]: any },
			d: Document,
			s: string,
			l: string,
			i: string,
		) {
			w[l] = w[l] || []
			w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
			const f = d.getElementsByTagName(s)[0] as HTMLElement
			const j = d.createElement(s) as HTMLScriptElement
			const dl = l !== 'dataLayer' ? '&l=' + l : ''
			j.async = true
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
			f.parentNode!.insertBefore(j, f)
		})(window, document, 'script', 'dataLayer', gtmId)
	}, [])

	return null
}

export default useGoogleTagManagerScript
